import { ToastOptions, toastController } from "@ionic/vue";

export const showErrorToast = async (message: string, options?: ToastOptions) => {
	const toast = await toastController.create({
		duration: 1500,
		position: "bottom",
		color: "danger",
		...options,
		message: message,
	});

	await toast.present();
};
