import apolloClient from "@/core/apollo";
import i18n from "@/core/i18n";
import appsQuery from "@/queries/apps.graphql";
import appByIdQuery from "@/queries/app.graphql";
import { useApp, useUser } from "@/stores";
import { IActiveAppData, IAppData, IRemoteAppData, IRequirements } from "@/types";
import faqByCategoryId from "@/queries/faqByCategory.graphql";
import { showErrorToast } from "@/utilities/error";
import { convertIconName } from "@/utilities/icon";
import { get } from "lodash";

export const getApps = async (): Promise<IAppData[] | null> => {
	const useAppStore = useApp();
	const useUserStore = useUser();

	const roles = useUserStore.user.data?.roles;

	if (!roles) {
		return null;
	}

	const currentViewUUID = useAppStore.viewUUID;

	try {
		const { data } = await apolloClient.query({
			query: appsQuery,
			variables: {
				roles: [":empty:", ...roles],
			},
		});

		/**
		 * prevent store from updating when route has changed after data is
		 * fetched.
		 */
		if (currentViewUUID !== useAppStore.viewUUID) {
			return null;
		}

		const appsData = data.entries.map((entry: IRemoteAppData) => ({
			...entry,
			img: get(entry, "img[0]", null),
			keywords: entry.keywords ? entry.keywords.split(",").map((keyword) => keyword.trim()) : [],
			appLocations: get(entry, "appLocations[0]", null),
		}));

		return appsData;
	} catch (error) {
		const { t } = i18n.global;

		await showErrorToast(t("local.error.api"));

		return null;
	}
};

export const getAppById = async (appId: string | string[]): Promise<IActiveAppData | null> => {
	const useAppStore = useApp();

	const currentViewUUID = useAppStore.viewUUID;

	try {
		const { data } = await apolloClient.query({
			query: appByIdQuery,
			variables: { id: appId },
		});

		const { app, tasks } = data;

		const appData = {
			...app,
			img: get(app, "img[0]", null),
			requirements: (app.requirements || []).map((requirement: IRequirements) => ({
				text: requirement.text || "",
				icon: { icon: convertIconName(requirement.icon.icon) || null },
			})),
			noticePicker: get(app, "noticePicker[0]", null),
			appLocations: get(app, "appLocations[0]", null),
		};

		if (appData.faq.length > 0) {
			const activeApp = { appData: appData, tasks: tasks };

			return activeApp;
		}

		const categoryIds = appData.categoryIds.map((categoryId: { id: string }) => categoryId.id);

		const { data: categoryData } = await apolloClient.query({
			query: faqByCategoryId,
			variables: { id: categoryIds },
		});

		const faqsFromCategory = categoryData.entries;

		const newAppData = {
			...appData,
			faq: faqsFromCategory,
		};

		/**
		 * prevent store from updating when route has changed after data is
		 * fetched.
		 */
		if (currentViewUUID !== useAppStore.viewUUID) {
			return null;
		}

		const activeApp = { appData: newAppData, tasks: tasks };

		return activeApp;
	} catch (error) {
		const { t } = i18n.global;

		await showErrorToast(t("local.error.api"));

		return null;
	}
};
