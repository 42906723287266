import { appsService } from "@/services";
import { defineStore } from "pinia";
import { IActiveAppData, IAppData } from "@/types";

interface IAppsData {
	isLoading: boolean;
	hasFetched: boolean;
	data: IAppData[] | undefined | null;
}

interface IActiveApp {
	isLoading: boolean;
	hasFetched: boolean;
	data: IActiveAppData | null;
}

interface IState {
	apps: IAppsData;
	activeApp: IActiveApp;
}

export default defineStore("apps", {
	state: (): IState => ({
		apps: {
			isLoading: false,
			hasFetched: false,
			data: undefined,
		},
		activeApp: {
			isLoading: false,
			hasFetched: false,
			data: null,
		},
	}),

	getters: {
		getHasApps(state): boolean {
			return state.apps.data ? state.apps.data?.length > 0 : false;
		},

		getHasActiveApp(state): boolean {
			return state.activeApp.data ? true : false;
		},

		getFilteredApps(state): (searchInput: string) => IAppData[] {
			return (searchInput: string) => {
				if (!state.apps.data) return [];

				const filteredTasks = state.apps.data.filter(
					(app) =>
						app.title.toLowerCase().includes(searchInput.toLowerCase()) ||
						app.keywords.some((keyword) => keyword.toLowerCase().includes(searchInput.toLowerCase()))
				);

				return filteredTasks;
			};
		},

		getAppById(state) {
			return (id: string): IAppData | undefined => state.apps.data?.find((app) => app.id === id);
		},
	},

	actions: {
		async fetchApps() {
			this.apps.isLoading = true;
			this.apps.hasFetched = false;

			const data = await appsService.getApps();

			this.apps.data = data;

			this.apps.isLoading = false;
			this.apps.hasFetched = true;
		},

		async fetchActiveApp(appId: string) {
			// we set the title of the app, because the header has no loading state
			if (this.activeApp.data) {
				const activeApp = this.getAppById(appId);

				this.activeApp.data.appData.title = activeApp?.title ?? "";
			}

			this.activeApp.isLoading = true;
			this.activeApp.hasFetched = false;

			const activeApp = await appsService.getAppById(appId);

			this.activeApp.data = activeApp;

			this.activeApp.isLoading = false;
			this.activeApp.hasFetched = true;
		},
	},
});
