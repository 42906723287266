import { ROUTER, KEYS } from "@/constants";
import Auth from "@/core/auth";
import { Preferences } from "@capacitor/preferences";

export const getValidToken = async () => {
	try {
		const data = await Auth.Instance.getValidToken();
		// Double check if a valid token is set in storage
		const { value } = await Preferences.get({ key: KEYS.MINTUS_API_TOKEN });

		if (!value) {
			throw new Error("refresh failed");
		}

		return data;
	} catch (error) {
		// When we can't get a valid token we redirect to login
		document.location.href = ROUTER.LOGIN_PATH;

		throw error;
	}
};
