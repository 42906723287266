import apolloClient from "@/core/apollo";
import tasksQuery from "@/queries/tasks.graphql";
import updateTaskScore from "@/queries/taskScore.graphql";
import taskByIdQuery from "@/queries/task.graphql";
import { useApp, useUser } from "@/stores";
import { ITaskData, IRemoteTaskData, IActiveTaskData, IRequirements } from "@/types";
import faqByCategoryId from "@/queries/faqByCategory.graphql";
import { showErrorToast } from "@/utilities/error";
import { convertIconName } from "@/utilities/icon";
import { get } from "lodash";
import { Preferences } from "@capacitor/preferences";
import newPeriodQuery from "@/queries/newPeriod.graphql";
import { KEYS } from "@/constants";
import i18n from "@/core/i18n";

const { t } = i18n.global;

interface ITasksArrayItem {
	taskId: string;
	endDate: string;
}

const getTasksArray = async () => {
	const { value } = await Preferences.get({ key: KEYS.MINTUS_TASKS });

	const tasksArray: ITasksArrayItem[] = value ? JSON.parse(value) : [];

	return tasksArray;
};

const getEndDate = (taskId: string, currentTasks: ITasksArrayItem[]): string | null => {
	const matchingTask = currentTasks.find((task) => task.taskId === taskId);

	return matchingTask ? matchingTask.endDate : null;
};

const setEndDate = (taskId: string, currentTasks: ITasksArrayItem[], endDate: string) => {
	const newTask = { taskId, endDate };

	currentTasks.push(newTask);
};

const shouldRenderTag = (taskId: string, newPeriodNumber: number, currentTasks: ITasksArrayItem[]) => {
	const currEndDate = getEndDate(taskId, currentTasks);
	const now = new Date();

	if (currEndDate !== null) {
		const currEndDateFormat = new Date(currEndDate);

		if (now >= currEndDateFormat) {
			return null;
		}

		return { text: t("ui.new"), class: "c-base-tag--secondary" };
	} else {
		now.setDate(now.getDate() + newPeriodNumber);

		setEndDate(taskId, currentTasks, now.toISOString());

		return { text: t("ui.new"), class: "c-base-tag--secondary" };
	}
};

export const getTasks = async (): Promise<ITaskData[] | null> => {
	const useAppStore = useApp();
	const useUserStore = useUser();

	const roles = useUserStore.user.data?.roles;

	if (!roles) {
		return null;
	}

	const currentViewUUID = useAppStore.viewUUID;

	try {
		const [tasksRemoteData, newPeriodNumber, localTasks] = await Promise.all([
			// Tasks data
			(async () => {
				const { data } = await apolloClient.query({
					query: tasksQuery,
					variables: {
						roles: [":empty:", ...roles],
					},
				});

				return data;
			})(),
			// New period data
			(async () => {
				const { data } = await apolloClient.query({
					query: newPeriodQuery,
					variables: {},
				});

				return data.globalSet.newIndicator;
			})(),
			await getTasksArray(),
		]);

		const tasksData = tasksRemoteData.entries.map((entry: IRemoteTaskData): ITaskData => {
			return {
				...entry,
				img: get(entry, "img[0]", null),
				keywords: entry.keywords ? entry.keywords.split(",").map((keyword) => keyword.trim()) : [],
				tag: shouldRenderTag(entry.id, newPeriodNumber, localTasks),
				deeplink: get(entry, "deeplink[0]", null),
				application: get(entry, "application[0]", null),
				appLocations: get(entry, "application[0].appLocations[0]", null),
			};
		});

		await Preferences.set({ key: KEYS.MINTUS_TASKS, value: JSON.stringify(localTasks) });

		// Put tasks with new tag at the top.
		tasksData.sort((a: ITaskData, b: ITaskData) => {
			if (b.tag !== null && a.tag === null) {
				return 1;
			} else if (b.tag === null && a.tag !== null) {
				return -1;
			} else {
				return 0;
			}
		});

		/**
		 * prevent store from updating when route has changed after data is
		 * fetched.
		 */
		if (currentViewUUID !== useAppStore.viewUUID) {
			return null;
		}

		return tasksData;
	} catch (error) {
		const { t } = i18n.global;

		await showErrorToast(t("local.error.api"));

		return null;
	}
};

export const getTaskById = async (taskId: string | string[]): Promise<IActiveTaskData | null> => {
	const useAppStore = useApp();

	const currentViewUUID = useAppStore.viewUUID;

	try {
		const { data } = await apolloClient.query({
			query: taskByIdQuery,
			variables: { id: taskId },
		});

		const { entry } = data;
		const application = get(entry, "application[0]", null);

		const taskData: IActiveTaskData = {
			...entry,
			img: get(entry, "img[0]", null),
			deeplink: get(entry, "deeplink[0]", null),
			application: application && {
				...application,
				img: get(application, "img[0]", null),
				requirements: (application.requirements || []).map((requirement: IRequirements) => ({
					text: requirement.text || "",
					icon: { icon: convertIconName(requirement.icon.icon) || null },
				})),
				appLocations: get(application, "appLocations[0]", null),
			},
		};

		const newTaskScore = taskData.taskScore + 1;

		apolloClient.query({
			query: updateTaskScore,
			variables: { id: taskId, taskScore: newTaskScore },
		});

		if (taskData.faq.length > 0) {
			return taskData;
		}

		const categoryIds = taskData.categoryIds.map((categoryId: { id: string }) => categoryId.id);

		const { data: categoryData } = await apolloClient.query({
			query: faqByCategoryId,
			variables: { id: categoryIds },
		});

		const faqsFromCategory = categoryData.entries;

		const newTaskData = {
			...taskData,
			faq: faqsFromCategory,
		};

		/**
		 * prevent store from updating when route has changed after data is
		 * fetched.
		 */
		if (currentViewUUID !== useAppStore.viewUUID) {
			return null;
		}

		return newTaskData;
	} catch (error) {
		const { t } = i18n.global;

		await showErrorToast(t("local.error.api"));

		return null;
	}
};
