import { buddiesService } from "@/services";
import { IBuddiesData } from "@/types";
import { defineStore } from "pinia";

interface IBuddies {
	isLoading: boolean;
	hasFetched: boolean;
	data: IBuddiesData | null;
}

interface IState {
	buddies: IBuddies;
}

export default defineStore("buddies", {
	state: (): IState => ({
		buddies: {
			isLoading: false,
			hasFetched: false,
			data: null,
		},
	}),

	getters: {
		getHasBuddies(state): boolean {
			return state.buddies.data ? true : false;
		},
	},

	actions: {
		async fetchBuddies() {
			this.buddies.isLoading = true;
			this.buddies.hasFetched = false;

			const data = await buddiesService.getBuddies();

			this.buddies.data = data;

			this.buddies.isLoading = false;
			this.buddies.hasFetched = true;
		},
	},
});
