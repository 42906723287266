import {
	lockClosedOutline,
	locationOutline,
	phonePortraitOutline,
	eyeOutline,
	browsersOutline,
	mailUnreadOutline,
	cameraOutline,
	playOutline,
	personAddOutline,
	saveOutline,
	reloadOutline,
	cardOutline,
	wifiOutline,
	shareSocialOutline,
	downloadOutline,
	logOutOutline,
	logInOutline,
	powerOutline,
	homeOutline,
	personOutline,
	checkmarkOutline,
	searchOutline,
	helpCircleOutline,
} from "ionicons/icons";

export const convertIconName = (iconName: string) => {
	if (iconName) {
		switch (iconName) {
			case "lock-closed":
				return lockClosedOutline;

			case "location":
				return locationOutline;

			case "phone-portrait":
				return phonePortraitOutline;

			case "eye":
				return eyeOutline;

			case "browser":
				return browsersOutline;

			case "mail-unread":
				return mailUnreadOutline;

			case "camera":
				return cameraOutline;

			case "play":
				return playOutline;

			case "person-add":
				return personAddOutline;

			case "save":
				return saveOutline;

			case "reload":
				return reloadOutline;

			case "card":
				return cardOutline;

			case "wifi":
				return wifiOutline;

			case "share-social":
				return shareSocialOutline;

			case "download":
				return downloadOutline;

			case "log-out":
				return logOutOutline;

			case "log-in":
				return logInOutline;

			case "power":
				return powerOutline;

			case "home":
				return homeOutline;

			case "person":
				return personOutline;

			case "checkmark":
				return checkmarkOutline;

			case "search":
				return searchOutline;

			default:
				return helpCircleOutline;
		}
	}

	return helpCircleOutline;
};
