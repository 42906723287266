<template>
	<ion-page>
		<ion-tabs @ionTabsDidChange="handleTabChange" class="c-navigation-container">
			<ion-router-outlet></ion-router-outlet>

			<ion-tab-bar slot="bottom" class="c-navigation" role="tablist">
				<ion-tab-button
					tab="applications"
					href="/tabs/applications"
					:class="{ 'c-navigation__tab--active': activeTab === 'applications' }"
					class="c-navigation__tab"
					:aria-label="t('ui.apps')"
					role="tab"
				>
					<ion-icon
						aria-hidden="true"
						:icon="activeTab === 'applications' ? grid : gridOutline"
						class="c-navigation__icon"
					/>
					<ion-label class="c-navigation__label">Applicaties</ion-label>
				</ion-tab-button>

				<ion-tab-button
					tab="tasks"
					href="/tabs/tasks"
					:class="{ 'c-navigation__tab--active': activeTab === 'tasks' }"
					class="c-navigation__tab"
					:aria-label="t('ui.tasks')"
					role="tab"
				>
					<ion-icon
						aria-hidden="true"
						:icon="activeTab === 'tasks' ? home : homeOutline"
						class="c-navigation__icon"
					/>
					<ion-label class="c-navigation__label">Taken</ion-label>
				</ion-tab-button>

				<ion-tab-button
					tab="help"
					href="/tabs/help"
					:class="{ 'c-navigation__tab--active': activeTab === 'help' }"
					class="c-navigation__tab"
					:aria-label="t('ui.help')"
					role="tab"
				>
					<ion-icon
						aria-hidden="true"
						:icon="activeTab === 'help' ? helpCircle : helpCircleOutline"
						class="c-navigation__icon"
					/>
					<ion-label class="c-navigation__label">Hulp</ion-label>
				</ion-tab-button>
			</ion-tab-bar>
		</ion-tabs>
	</ion-page>
</template>

<script setup lang="ts">
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from "@ionic/vue";
import { home, homeOutline, helpCircle, helpCircleOutline, grid, gridOutline } from "ionicons/icons";
import { ref } from "vue";
import router from "../constants/router";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const activeTab = ref(router.HOME_NAME);

const handleTabChange = (event: any) => {
	activeTab.value = event.tab;
};
</script>

<style lang="scss">
// =============================================================================
// Block
// =============================================================================
.c-navigation {
	--ripple-color: transparent;
	--background: var(--ion-color-tertiary-contrast);
	--color: var(--ion-color-primary-contrast);

	height: 4rem;
	padding: 0.5rem 0.75rem 1rem;
	padding-bottom: calc(1rem + env(safe-area-inset-bottom));

	border-radius: 1rem 1rem 0 0;

	gap: 1rem;

	@media screen and (min-width: theme("screens.lg")) {
		--color: var(--ion-color-primary-contrast);

		position: absolute;
		bottom: 0;

		align-self: center;
		width: 30%;
		margin-bottom: 1rem;
		padding: 1rem;

		border-radius: 1rem;
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-navigation__tab {
	border-radius: 1rem;

	transition: 0.3s ease all;

	&:hover {
		&::part(native) {
			color: var(--ion-color-primary-contrast);
		}
	}
}

.c-navigation-container {
	--background: var(--ion-color-light);
	background: var(--ion-color-light);

	@media screen and (min-width: theme("screens.lg")) {
		--background: var(--ion-color-background-desktop);
		background: var(--ion-color-background-desktop);
	}
}

.c-navigation__label {
	// Prevent desktop bug --> jumping margin when switching tabs
	margin: 0;

	font-weight: 700;
	font-size: theme("fontStyles.body-small.font-size");
}

.c-navigation__icon {
	// Prevent desktop bug --> jumping margin when switching tabs
	margin: 0;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-navigation__tab--active {
	background: var(--ion-color-secondary);
	color: var(--ion-color-secondary-contrast);
	border-radius: 1rem;

	&:hover {
		&::part(native) {
			color: var(--ion-color-primary-contrast);
		}
	}
}
</style>
