import { warningsService } from "@/services";
import { defineStore } from "pinia";
import { IWarning } from "@/types";

interface IGlobalWarning {
	isLoading: boolean;
	hasFetched: boolean;
	data: IWarning | null;
}

interface IState {
	globalWarning: IGlobalWarning;
}

export default defineStore("warning", {
	state: (): IState => ({
		globalWarning: {
			isLoading: false,
			hasFetched: false,
			data: null,
		},
	}),

	getters: {
		getHasWarning(state): boolean {
			return state.globalWarning.data ? true : false;
		},
	},

	actions: {
		async fetchGlobalWarning() {
			this.globalWarning.isLoading = true;
			this.globalWarning.hasFetched = false;

			const data = await warningsService.getGlobalWarning();

			this.globalWarning.data = data;

			this.globalWarning.isLoading = false;
			this.globalWarning.hasFetched = true;
		},
	},
});
