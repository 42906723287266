import { tasksService } from "@/services";
import { defineStore } from "pinia";
import { IActiveTaskData, ITaskData } from "@/types";

interface ITasks {
	isLoading: boolean;
	hasFetched: boolean;
	data: ITaskData[] | undefined | null;
}

interface IActiveTask {
	isLoading: boolean;
	hasFetched: boolean;
	data: IActiveTaskData | null;
}

interface IState {
	tasks: ITasks;
	activeTask: IActiveTask;
}

export default defineStore("tasks", {
	state: (): IState => ({
		tasks: {
			isLoading: false,
			hasFetched: false,
			data: undefined,
		},
		activeTask: {
			isLoading: false,
			hasFetched: false,
			data: null,
		},
	}),

	getters: {
		getHasTasks(state): boolean {
			return state.tasks.data ? state.tasks.data?.length > 0 : false;
		},

		getHasActiveTask(state): boolean {
			return state.activeTask.data ? true : false;
		},

		getFilteredTasks(state): (searchInput: string) => ITaskData[] {
			return (searchInput: string) => {
				if (!state.tasks.data) {
					return [];
				}

				const filteredTasks = state.tasks.data.filter(
					(task) =>
						task.title.toLowerCase().includes(searchInput.toLowerCase()) ||
						task.keywords.some((keyword) => keyword.toLowerCase().includes(searchInput.toLowerCase()))
				);

				return filteredTasks;
			};
		},

		getTaskById(state) {
			return (id: string): ITaskData | undefined => state.tasks.data?.find((task) => task.id === id);
		},
	},

	actions: {
		async fetchTasks() {
			this.tasks.isLoading = true;
			this.tasks.hasFetched = false;

			const data = await tasksService.getTasks();

			this.tasks.data = data;

			this.tasks.isLoading = false;
			this.tasks.hasFetched = true;
		},

		async fetchActiveTask(taskId: string) {
			// we set the title of the task, because the header has no loading state
			if (this.activeTask.data) {
				const activeTask = this.getTaskById(taskId);

				this.activeTask.data.title = activeTask?.title ?? "";
			}

			this.activeTask.isLoading = true;
			this.activeTask.hasFetched = false;

			const activeTask = await tasksService.getTaskById(taskId);

			this.activeTask.data = activeTask;

			this.activeTask.isLoading = false;
			this.activeTask.hasFetched = true;
		},

		resetActiveTask() {
			this.activeTask = {
				isLoading: false,
				hasFetched: false,
				data: null,
			};
		},
	},
});
