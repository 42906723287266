import { faqService } from "@/services";
import { defineStore } from "pinia";

interface IFaq {
	isLoading: boolean;
	hasFetched: boolean;
	data: any[] | null;
}

interface IActiveFaq {
	isLoading: boolean;
	hasFetched: boolean;
	data: any;
}

interface IState {
	faq: IFaq;
	activeFaq: IActiveFaq;
}

export default defineStore("faq", {
	state: (): IState => ({
		faq: {
			isLoading: false,
			hasFetched: false,
			data: [],
		},
		activeFaq: {
			isLoading: false,
			hasFetched: false,
			data: null,
		},
	}),

	getters: {
		getHasFaqItems(state): boolean {
			return state.faq.data ? state.faq.data?.length > 0 : false;
		},
	},

	actions: {
		async fetchFaq() {
			this.faq.isLoading = true;
			this.faq.hasFetched = false;

			const data = await faqService.getFaq();

			this.faq.data = data;

			this.faq.isLoading = false;
			this.faq.hasFetched = true;
		},
	},
});
