import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import TabsPage from "../views/TabsPage.vue";
import { KEYS, ROUTER } from "@/constants";
import mixpanel from "mixpanel-browser";

import Auth from "@/core/auth";
import { filter, switchMap, take } from "rxjs";
import { useApp, useUser } from "@/stores";
import { Preferences } from "@capacitor/preferences";

export let isAdminLoggedIn = false;

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		redirect: ROUTER.HOME_PATH,
	},
	{
		path: "/tabs/",
		component: TabsPage,
		children: [
			{
				path: "",
				redirect: ROUTER.HOME_PATH,
			},
			{
				path: "applications",
				component: () => import("@/views/ApplicationPage.vue"),
			},
			{
				path: "tasks",
				component: () => import("@/views/TaskPage.vue"),
			},
			{
				path: "help",
				name: "help",
				component: () => import("@/views/HelpPage.vue"),
			},
		],
	},
	{
		path: "/faq",
		name: "UnauthenticatedHelp",
		component: () => import("@/views/HelpPage.vue"),
	},
	{
		path: "/task-detail-page/:id",
		name: "TaskDetailPage",
		component: () => import("@/views/TaskDetailPage.vue"),
	},
	{
		path: "/app-detail-page/:id",
		name: "AppDetailPage",
		component: () => import("@/views/AppDetailPage.vue"),
	},
	{
		path: "/profile",
		name: "Profile",
		component: () => import("@/views/ProfilePage.vue"),
	},
	{
		path: "/file",
		name: "File",
		component: () => import("@/views/FilePage.vue"),
	},
	{
		path: "/pay",
		name: "Pay",
		component: () => import("@/views/PayPage.vue"),
	},
	{
		path: ROUTER.LOGIN_PATH,
		name: ROUTER.LOGIN_NAME,
		component: () => import("@/views/LoginPage.vue"),
	},
	{
		path: "/admin-login",
		name: "AdminLogin",
		component: () => import("@/views/AdminLoginPage.vue"),
	},
	{
		path: "/authcallback",
		name: "RedirectPage",
		component: () => import("@/views/RedirectPage.vue"),
	},
	{
		path: "/endsession",
		name: "EndRedirectPage",
		component: () => import("@/views/EndRedirectPage.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes,
});

export const setIsAdminLoggedIn = (value: boolean) => {
	isAdminLoggedIn = value;
};

const unAuthenticatedRoutes = ["RedirectPage", "EndRedirectPage", "UnauthenticatedHelp"];

let initToRoute: string | null = null;

const fetchUser = async () => {
	const useAppStore = useApp();
	const useUserStore = useUser();

	if (!useUserStore.user.data) {
		useAppStore.setIsLoading(true);
		const user = await useUserStore.fetchUser();
		useAppStore.setIsLoading(false);

		if (!user) {
			useUserStore.setIsAuthenticated(false);

			return false;
		}

		useUserStore.setIsAuthenticated(true);

		initToRoute = null;
	}

	return true;
};

router.beforeEach((to, _, next) => {
	const useUserStore = useUser();

	if (
		!useUserStore.user.isAuthenticated &&
		to.name !== ROUTER.LOGIN_NAME &&
		!unAuthenticatedRoutes.includes(to.name as string)
	) {
		initToRoute = to.path;
	}

	if (to.name?.toString() === "AdminLogin") {
		return next();
	}

	if (isAdminLoggedIn) {
		if (to.name === ROUTER.LOGIN_NAME) {
			return next({ path: ROUTER.HOME_PATH });
		}

		return next();
	}

	if (to.name === ROUTER.LOGIN_NAME) {
		return next();
	}

	if (!unAuthenticatedRoutes.includes(to.name?.toString() ?? "")) {
		Auth.Instance.initComplete$
			.pipe(
				filter((complete) => complete),
				switchMap(() => Auth.Instance.isAuthenticated$),
				take(1)
			)
			.subscribe(async (isAuthenticated) => {
				useUserStore.setIsAuthenticated(isAuthenticated);

				if (isAuthenticated) {
					const user = await fetchUser();

					if (user) {
						if (to.name === ROUTER.LOGIN_NAME) {
							/**
							 * When user is logged and is accessing login,
							 * redirect to homePath or the last known route
							 * before the user had to authenticate again.
							 */
							next({ path: initToRoute ?? ROUTER.HOME_PATH });

							initToRoute = null;

							return;
						}

						return next();
					}

					// user is not logged in
					if (to.name !== ROUTER.LOGIN_NAME) {
						// redirect to login page
						return next({ path: ROUTER.LOGIN_PATH });
					}

					return next();
				} else {
					try {
						/**
						 * If the user isn't authenticated anymore try
						 * refreshing the access token
						 */
						await Auth.Instance.refreshToken();

						const { value } = await Preferences.get({ key: KEYS.MINTUS_API_TOKEN });

						if (!value) {
							throw new Error("refresh failed");
						}

						const user = await fetchUser();

						if (!user) {
							throw new Error("no user found");
						}

						if (to.name === ROUTER.LOGIN_NAME) {
							// when user is logged and is accessing login, redirect to homePath
							next({ path: initToRoute ?? ROUTER.HOME_PATH });

							initToRoute = null;

							return;
						}

						return next();
					} catch {
						if (to.name !== ROUTER.LOGIN_NAME) {
							// redirect to login page
							return next({ path: ROUTER.LOGIN_PATH });
						}

						return next();
					}
				}
			});
	} else {
		return next();
	}
});

let skipNextResumeEvent = false;

/**
 * Resume event gets fired (on Android) when browser closes. This happens before
 * Auth.Instance saves the token to storage. That's why we set isLoggingIn to
 * true when the browser opens and to false when it closes.
 */
Auth.browser.browserCloseListener(() => {
	const useAppStore = useApp();

	useAppStore.setIsLoggingIn(false);
	skipNextResumeEvent = true;
});

/**
 * Try to refresh token after app returns from background to make sure we have
 * access to the latest access token
 */
document.addEventListener("resume", async () => {
	if (isAdminLoggedIn) {
		return;
	}

	const useAppStore = useApp();

	if (useAppStore.isLoggingIn || skipNextResumeEvent) {
		skipNextResumeEvent = false;

		return;
	}

	try {
		const { value } = await Preferences.get({ key: KEYS.MINTUS_API_TOKEN });

		await Auth.Instance.refreshToken();

		if (!value) {
			throw new Error("refresh failed");
		}

		const user = await fetchUser();

		if (!user) {
			throw new Error("no user found");
		}
	} catch (error) {
		// When we can't get a valid token we redirect to login
		document.location.href = ROUTER.LOGIN_PATH;
	}
});

router.beforeResolve(({ fullPath }) => {
	if (import.meta.env.PROD) {
		const useAppStore = useApp();

		if (useAppStore.mixpanelInit) {
			mixpanel.track("page_view", { fullPath });
		}
	}
});

export default router;
