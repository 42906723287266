import { payService } from "@/services";
import { IPagination, IPaySlip } from "@/types";
import { defineStore } from "pinia";

interface IPaySlips {
	isLoading: boolean;
	hasFetched: boolean;
	data: IPaySlip[] | undefined | null;
	pagination: IPagination;
}

interface IState {
	payslips: IPaySlips;
}

export default defineStore("pay", {
	state: (): IState => ({
		payslips: {
			isLoading: false,
			hasFetched: false,
			data: undefined,
			pagination: {
				currentPage: 0,
				amountOfPages: 1,
				pageSize: 1,
				totalAmount: 1,
				firstRowOnPage: 1,
				lastRowOnPage: 1,
			},
		},
	}),

	getters: {
		getHasReachedTotalAmountOfPages(state) {
			return state.payslips.pagination.currentPage >= state.payslips.pagination.amountOfPages;
		},
	},

	actions: {
		resetCurrentPage() {
			this.payslips.pagination.currentPage = 0;
		},

		async fetchPayslips(loadMore = false, year?: string, type?: string) {
			if (!loadMore) {
				this.resetCurrentPage();
				this.payslips.isLoading = true;
				this.payslips.hasFetched = false;
			}

			this.payslips.pagination.currentPage++;

			const data = await payService.getPayslips(this.payslips.pagination.currentPage, year, type);

			if (data) {
				const { results, pagination } = data;

				if (this.payslips.data && loadMore) {
					this.payslips.data.push(...results);
				} else {
					this.payslips.data = results;
				}

				this.payslips.pagination = pagination;
			}

			this.payslips.isLoading = false;
			this.payslips.hasFetched = true;
		},

		async fetchPayslipUrl(payslipId: string): Promise<Blob | null | undefined> {
			if (!payslipId) {
				this.resetCurrentPage();
			}

			return await payService.getPayslipUrl(payslipId);
		},

		async fetchAdminPayslipUrl(): Promise<Blob | null | undefined> {
			return await payService.getAdminPayslipUrl();
		},

		async fetchAdminPayslips() {
			this.payslips.isLoading = true;
			this.payslips.hasFetched = false;

			const data = payService.getAdminPayslips();

			if (data) {
				const { results, pagination } = data;

				this.payslips.data = results;

				this.payslips.pagination = pagination;
			}

			this.payslips.isLoading = false;
			this.payslips.hasFetched = true;
			this.payslips.pagination.currentPage++;
		},
	},
});
