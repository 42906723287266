import apolloClient from "@/core/apollo";
import i18n from "@/core/i18n";
import { showErrorToast } from "@/utilities/error";
import buddiesQuery from "@/queries/buddies.graphql";
import { get } from "lodash";
import { IBuddiesData } from "@/types";

export const getBuddies = async (): Promise<IBuddiesData | null> => {
	try {
		const { data } = await apolloClient.query({
			query: buddiesQuery,
			variables: {},
		});

		const buddiesData: IBuddiesData = {
			...data.globalSet,
			file: get(data.globalSet, "file[0]", null),
		};

		return buddiesData;
	} catch (error) {
		const { t } = i18n.global;

		await showErrorToast(t("local.error.api"));

		return null;
	}
};
