import { userService } from "@/services";
import { defineStore } from "pinia";
import { IUserData } from "@/types";

interface IUser {
	isAuthenticated: boolean;
	isLoading: boolean;
	hasFetched: boolean;
	data: IUserData | undefined | null;
}

interface IState {
	user: IUser;
	refreshTimeout: ReturnType<typeof setTimeout> | null;
}

export default defineStore("user", {
	state: (): IState => ({
		user: {
			isAuthenticated: false,
			isLoading: false,
			hasFetched: false,
			data: undefined,
		},
		refreshTimeout: null,
	}),

	getters: {
		getFullName(state): string {
			const fullName =
				state.user.data?.firstName && state.user.data?.lastName
					? `${state.user.data?.firstName} ${state.user.data?.lastName}`
					: "";

			return fullName;
		},
	},

	actions: {
		async fetchUser() {
			this.user.isLoading = true;
			this.user.hasFetched = false;

			const data = await userService.getUser();

			this.user.data = data;

			this.user.isLoading = false;
			this.user.hasFetched = true;

			return data;
		},

		async fetchAdminUser() {
			this.user.isLoading = true;
			this.user.hasFetched = false;

			const data = await userService.getAdminUser();

			this.user.data = data;

			this.user.isLoading = false;
			this.user.hasFetched = true;

			return data;
		},

		setIsAuthenticated(isAuth: boolean) {
			this.user.isAuthenticated = isAuth;
		},
	},
});
