<template>
	<ion-app>
		<ion-router-outlet />

		<BaseLoader class="h-full absolute top-0 bg-white" v-show="useAppStore.isLoading" />

		<div v-show="!useIsNetworkConnected" class="no-internet-popup">
			<NoInternet />
		</div>
	</ion-app>
</template>

<script setup lang="ts">
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { translationsService } from "@/services";
import { useNetworkHook } from "@/hooks";
import { onBeforeMount } from "vue";
import { BaseLoader, NoInternet } from "./components";
import { useApp, useWarnings } from "@/stores";

const useWarningsStore = useWarnings();
const useAppStore = useApp();

const { useSetNetworkConnectionStatusChangeListener, useIsNetworkConnected } = useNetworkHook();

onBeforeMount(async () => {
	// TODO: refetch as soon as connection is made to prevent empty content after app startup
	useSetNetworkConnectionStatusChangeListener();

	await Promise.all([translationsService.getTranslations(), useWarningsStore.fetchGlobalWarning()]);
});
</script>

<style lang="scss" scoped>
.no-internet-popup {
	position: absolute;
	z-index: 999;

	width: 100%;
	height: 100%;

	background-color: var(--ion-color-primary-contrast);
}
</style>
