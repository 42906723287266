import apolloClient from "@/core/apollo";
import i18n from "@/core/i18n";
import helpPageFaqQuery from "@/queries/helpPageFaq.graphql";
import faqByCategoryId from "@/queries/faqByCategory.graphql";
import { useApp } from "@/stores";
import { showErrorToast } from "@/utilities/error";
import { IFaqItem } from "@/types";

export const getFaq = async (): Promise<IFaqItem[] | null> => {
	const useAppStore = useApp();

	const currentViewUUID = useAppStore.viewUUID;

	try {
		const { data } = await apolloClient.query({
			query: helpPageFaqQuery,
			variables: {},
		});

		const faqsManual = data.entry.faq;

		if (faqsManual.length > 0) {
			return faqsManual;
		}

		const categoryIds = data.entry.category.map((category: { id: string }) => category.id);

		const { data: categoryData } = await apolloClient.query({
			query: faqByCategoryId,
			variables: { id: categoryIds },
		});

		const faqsCategory = categoryData.entries;

		/**
		 * prevent store from updating when route has changed after data is
		 * fetched.
		 */
		if (currentViewUUID !== useAppStore.viewUUID) {
			return null;
		}

		return faqsCategory;
	} catch (error) {
		const { t } = i18n.global;

		await showErrorToast(t("local.error.api"));

		return null;
	}
};
