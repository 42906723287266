import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";

interface IState {
	viewUUID: string | null;
	mixpanelInit: boolean;
	isLoading: boolean;
	isLoggingIn: boolean;
}

export default defineStore("app", {
	state: (): IState => ({
		viewUUID: null,
		mixpanelInit: false,
		isLoading: false,
		isLoggingIn: false,
	}),

	actions: {
		createViewUuid(): string {
			this.viewUUID = uuidv4();

			return this.viewUUID;
		},

		setIsLoading(isLoading: boolean) {
			this.isLoading = isLoading;
		},

		setIsLoggingIn(isLoggingIn: boolean) {
			this.isLoggingIn = isLoggingIn;
		},
	},
});
