import { IPaySlip, IPaySlipRemote, IPaySlipsData, IRemotePaySlipsData } from "@/types";
import paySlipsData from "@/server/payslips.json";
import i18n from "@/core/i18n";
import { showErrorToast } from "@/utilities/error";
import { token } from "@/utilities";

export const getPayslips = async (
	currentPage: number,
	selectedYear?: string,
	selectedType?: string
): Promise<IPaySlipsData | null> => {
	try {
		const { accessToken } = await token.getValidToken();

		let params = "";

		if (selectedYear) {
			params += `&Year=${selectedYear}`;
		}

		if (selectedType) {
			params += `&Type=${selectedType}`;
		}

		const response = await fetch(
			`https://mintusprdidapp.azurewebsites.net/api/v1/payslip?CurrentPage=${currentPage}${params}`,
			{
				method: "GET",
				headers: {
					accept: "*/*",
					Authorization: `Bearer ${accessToken}`,
				},
			}
		);

		const data: IRemotePaySlipsData = await response.json();

		const payslips: IPaySlip[] = data.results.map(mapPaySlip);

		data.results = payslips;

		return {
			...data,
			results: payslips,
		};
	} catch (error) {
		const { t } = i18n.global;

		await showErrorToast(t("local.error.api"));

		return null;
	}
};

export const getAdminPayslips = (): IPaySlipsData | null => {
	try {
		const data: IRemotePaySlipsData = paySlipsData;

		const payslips: IPaySlip[] = data.results.map(mapPaySlip);

		data.results = payslips;

		return {
			...data,
			results: payslips,
		};
	} catch (error) {
		return null;
	}
};

const mapPaySlip = (paySlip: IPaySlipRemote): IPaySlip => ({
	...paySlip,
	title: paySlip.date ? new Date(paySlip.date).toLocaleDateString("nl-BE", { month: "long", year: "numeric" }) : "",
	date: paySlip.date ? new Date(paySlip.date).toLocaleDateString("en-GB") : "",
});

export const getPayslipUrl = async (payslipId: string): Promise<Blob | null | undefined> => {
	try {
		const { accessToken } = await token.getValidToken();

		const response = await fetch(`https://mintusprdidapp.azurewebsites.net/api/v1/payslip/${payslipId}`, {
			method: "GET",
			headers: {
				accept: "*/*",
				Authorization: `Bearer ${accessToken}`,
			},
		});

		const blob = await response.blob();

		return blob;
	} catch (error) {
		const { t } = i18n.global;

		await showErrorToast(t("local.error.api"));

		return null;
	}
};
export const getAdminPayslipUrl = async (): Promise<Blob | null | undefined> => {
	try {
		const response = await fetch(`./pay-stub-template.pdf`);

		const blob = await response.blob();

		return blob;
	} catch (error) {
		const { t } = i18n.global;

		await showErrorToast(t("local.error.api"));

		return null;
	}
};
