import { Network, ConnectionStatus } from "@capacitor/network";
import { ref } from "vue";

export default () => {
	const useIsNetworkConnected = ref(true);
	const prevIsNetworkConnected = ref(true);

	const onNetworkStatusChange = async ({ connected }: ConnectionStatus) => {
		/**
		 * On android onNetworkStatusChange gets triggered when unlocking the
		 * device. This causes a redirect to the dashboard
		 * (see enableNetworkConnection). Only toggle when the connection was
		 * disrupted or is disrupted at the moment.
		 */
		if (!prevIsNetworkConnected.value || !connected) {
			useIsNetworkConnected.value = connected;
		}

		prevIsNetworkConnected.value = connected;
	};

	const useSetNetworkConnectionStatusChangeListener = () => {
		Network.addListener("networkStatusChange", onNetworkStatusChange);
	};

	return {
		useIsNetworkConnected,
		useSetNetworkConnectionStatusChangeListener,
	};
};
