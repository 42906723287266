import apolloClient from "@/core/apollo";
import i18n from "@/core/i18n";
import globalWarningQuery from "@/queries/globalWarning.graphql";
import { showErrorToast } from "@/utilities/error";
import { IWarning } from "@/types";
import { get } from "lodash";

export const getGlobalWarning = async (): Promise<IWarning | null> => {
	try {
		const { data } = await apolloClient.query({
			query: globalWarningQuery,
			variables: {},
		});

		const notices = data.globalSet;

		const noticeData: IWarning = {
			...notices,
			noticePicker: get(notices, "noticePicker[0]", null),
		};

		return noticeData;
	} catch (error) {
		const { t } = i18n.global;

		await showErrorToast(t("local.error.api"));

		return null;
	}
};
