import { createApp, provide, h } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Cropper */
import "cropperjs/dist/cropper.css";

/* Apollo */
import { DefaultApolloClient } from "@vue/apollo-composable";
import apolloClient from "@/core/apollo";

/* i18n */
import i18n from "@/core/i18n";

/* Mixpanel */
import mixpanel from "mixpanel-browser";

/* Pinia */
import { createPinia } from "pinia";
import { useApp } from "./stores";

/* Sentry */
import * as Sentry from "@sentry/vue";

const pinia = createPinia();

const app = createApp({
	setup() {
		provide(DefaultApolloClient, apolloClient);
	},

	render: () => h(App),
})
	.use(IonicVue)
	.use(router)
	.use(pinia)
	.use(i18n);

if (import.meta.env.PROD) {
	const useAppStore = useApp();

	Sentry.init({
		app,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		tracesSampleRate: 0.5,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});

	mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
		debug: false,
		loaded: () => {
			useAppStore.mixpanelInit = true;
		},
	});
}

router.isReady().then(() => {
	app.mount("#app");
});
