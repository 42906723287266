import { createI18n, I18nOptions } from "vue-i18n";
import nl from "@/locales/nl.json";

const options: I18nOptions = {
	legacy: false,
	locale: "nl",
	fallbackLocale: "nl",
	messages: {
		nl,
	},
	fallbackWarn: false,
	missingWarn: false,
};

const i18n = createI18n(options);

export default i18n;
