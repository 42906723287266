import { ApolloClient, createHttpLink, InMemoryCache, DefaultOptions } from "@apollo/client/core";

const defaultOptions: DefaultOptions = {
	query: {
		fetchPolicy: "no-cache",
	},
};

// Cache implementation
const cache = new InMemoryCache();

const httpLink = createHttpLink({
	uri: import.meta.env.VITE_API_URL,
	headers: {
		authorization: import.meta.env.VITE_API_TOKEN,
	},
});

const apolloClient = new ApolloClient({
	link: httpLink,
	defaultOptions,
	cache,
});

export default apolloClient;
