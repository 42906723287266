<template>
	<div class="c-no-internet">
		<IonIcon class="c-no-internet__icon" :icon="reloadOutline" />

		<h1 class="typo-h1">{{ t("local.no-internet.title") }}</h1>

		<p class="c-no-internet__subtitle">{{ t("local.no-internet.description") }}</p>
	</div>
</template>

<script lang="ts" setup>
import { reloadOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<style lang="scss" scoped>
// =============================================================================
// Block
// =============================================================================
.c-no-internet {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 2rem;

	text-align: center;

	gap: 1rem;
}

.c-no-internet ion-icon {
	width: 3.5rem;
	height: 3.5rem;

	color: var(--ion-color-medium);
}
</style>
